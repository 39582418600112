<template>
  <Teleport to="body">
    <ModalsContainer />
    <VueFinalModal v-model="modelValue"
      @closed="emit('closed')"
      class="flex justify-center items-center"
      :content-class="`max-h-[calc(100vh-70px)] flex flex-col mx-4 bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-2 ${contentClass}`"
    >
      <div class="border-b p-4 font-bold mb-3 flex justify-between items-center">
        <slot name="header" /> <button @click="onCloseClicked" class="transition-opacity hover:opacity-50"><Icon name="jam:close" size="24px" /></button>
      </div>
      <div :class="containerClass">
        <slot />
      </div>

<!--      <button class="mt-1 ml-auto px-2 border rounded-lg">-->
<!--        Confirm-->
<!--      </button>-->
    </VueFinalModal>
  </Teleport>
</template>

<script setup lang="ts">
import { VueFinalModal, ModalsContainer } from 'vue-final-modal';
import 'vue-final-modal/style.css';

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  contentClass: {
    type: String,
    default: 'max-w-xl',
  },
  containerClass: {
    type: String,
    default: 'p-4 pt-0 overflow-auto',
  },
})
const emit = defineEmits(['update:modelValue', 'closed']);

const modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value: any) {
    emit('update:modelValue', value);
  }
});

watchEffect(() => {
  if (process.client) {
    if (modelValue.value) {
      document.body.classList.add('modal-shown');
    } else {
      document.body.classList.remove('modal-shown');
    }
  }
});

function onCloseClicked() {
  modelValue.value = false;
  emit('closed');
}
</script>

<style scoped>

</style>
